<template>
  <div>
    <h1>{{ id ? '修改' : '新建' }}用户</h1>
    <el-form @submit.native.prevent="save" class="el-form--max-width-400">
      <el-form-item label="用户名">
        <el-input v-model="model.username" />
      </el-form-item>
      <el-form-item label="角色">
        <el-select clearable v-model="model.role_id">
          <el-option
            v-for="role in roles"
            :key="role.id"
            :label="role.name"
            :value="role.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="model.password" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        username: '',
        role_id: '',
        password: '',
      },
      roles: [],
    }
  },
  created() {
    this.id && this.fetch()
    this.getRoles()
  },
  methods: {
    async fetch() {
      const { data: user } = await this.$http.get(`users/${this.id}`)
      this.model = Object.assign(this.model, user)
    },
    async getRoles() {
      const { data: roles } = await this.$http.get('roles?paginate=0&order=asc')
      this.roles = roles
    },
    async save() {
      let data = {}
      if (!this.model.password) {
        data = Object.assign({}, this.model)
        delete data.password
      } else {
        data = this.model
      }
      if (this.id) {
        await this.$http.put(`users/${this.id}`, data)
      } else {
        await this.$http.post('users', this.model)
      }
      this.$router.push('/user/list')
      this.$message.success('保存成功')
    },
  },
})
</script>
